#container--footer {
    min-height: 4rem;
    width: 100%;
    background: var(--clr-neutral-300);
    font-size: var(--fs-200);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#container--footer__sticky {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: 2rem 0 0 0;
    min-height: 4rem;
    width: 100%;
    background: var(--clr-neutral-300);
    font-size: var(--fs-200);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#wrapper--footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer__div {
    width: 33%;
    padding: 0 var(--spacer);
    display: flex;
    flex-direction: column;
}

.footer__divl {
    display: flex;
    align-items: flex-start;
}

.footer__divc {
    align-items: center;
}

.footer__divr {
    flex-direction: row;
    justify-content: end;
}

.footer__logo {
    max-width: 50%;
}

.footer__icon {
    padding: 0 var(--spacer)
}