:root {
    --box-shadow-btn__requestDetails:
        0 1rem 1.25rem 0 #0f9ebb
        0 -0.25rem 1.5rem var(--clr-neutral-800) inset,
        0 0.75rem 0.5rem hsl(206, 92%, 91%) inset,
        0 0.25rem 0.5rem 0 #4882b6 inset;
}

.container--requestDetails {
    margin: 8.5rem 0 5rem;
}

.requestDetails--article {
    width: 50rem;
    display: block;
    margin: 0 auto;
}

.requestDetails--title {
    margin: 0;
    padding: 1.25rem 2rem 1rem;
    border-radius: 6px 6px 0 0;
    color: var(--clr-neutral-100);
    background-color: #4882b6;
    font-size: 2rem;
    font-weight: 500;
    text-shadow: 3px 3px 8px var(--clr-neutral-800);
}

.requestDetails--wrapper {
    padding: 0 20px 15px;
    border-radius: 0 0 6px 6px;
    background-color: var(--clr-neutral-100);
    background-image: linear-gradient(0deg, #fffaf2, #edefee);
    box-shadow: 0 5px 20px #025375;
}

.requestDetails--requestInfo__title,
.requestDetails--contactInfo__title {
    font-size: 1.5rem;
    font-weight: 500;
    padding: 1.5rem 0 1rem
}

.requestDetails--contactInfo__name, 
.requestDetails--contactInfo__address,
.requestDetails--contactInfo__cityStzteZip, 
.requestDetails--contactInfo__phone, 
.requestDetails--contactInfo__email {
    padding-left: 2rem;
    font-size: 1.25rem;
}

.button--requestDetails {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1rem auto;
}

.btn--requestDetails {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.08rem;

    cursor: pointer;
	position: relative;
	padding: 1.2rem 4.5rem;
	border-radius: 3.75rem;
	line-height: 1.5rem;
	font-size: 1.5rem;

    padding: .735rem 2.2rem;
	font-size: 1.2rem;

	font-weight: 500;

    border: 1px solid var(--clr-neutral-800);
    background-image: linear-gradient(-180deg, #658cad 0%, #4882b6 100%);
    box-shadow: var(--box-shadow-btn__requestDetails);
}

.btn--requestDetails span {
    color: transparent;
    background-image: linear-gradient(0deg, #bcd4e7 0%, var(--clr-neutral-100) 100%);
    -webkit-background-clip: text;
	background-clip: text;
	filter: drop-shadow(0 2px 2px var(--clr-neutral-800));
}

.btn--requestDetails::before {
    content: '';
    display: block;
    height: 0.16rem;
    position: absolute;
    top: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 5.8rem);
    background: var(--clr-neutral-100);
    border-radius: 100%;
    opacity: 0.7;
    background-image: linear-gradient(-270deg, rgba(255,255,255,0.00) 0%, #fff 20%, #fff 80%, rgba(255,255,255,0.00) 100%);
}

.btn--requestDetails::after {
    content: "";
	display: block;
	height: 0.16rem;
	position: absolute;
	bottom: 0.50rem;
	left: 50%;
	transform: translateX(-50%);
	width: calc(100% - 5.8rem);
	background: var(--clr-neutral-100);
	border-radius: 100%;
}

