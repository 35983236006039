/* Custom properties */
:root {
    --clr-neutral-900: rgb(29, 33, 43);
    --clr-neutral-800: rgb(5, 25, 78);
    --clr-neutral-700: hsla(221, 18%, 32%, 1); /* independence */
    --clr-neutral-300: hsla(187, 6%, 69%, 1); /* silver-sand */
    --clr-neutral-100: hsla(224, 52%, 94%, 1); /* ghost white */
    
    --clr-accent-lt-blue: hsla(217, 89%, 83%, 1); /* baby-blue-eyes */
    --clr-accent-dk-blue: rgb(92, 123, 172);
    --clr-accent-lt-green: rgb(163, 185, 127);
    --clr-accent-dk-green: hsla(83, 44%, 23%, 1); /* army-green */
    --clr-accent-olive-green: rgb(41, 56, 15);
    
    --clr-cornsilk: hsla(48, 100%, 94%, 1);
    --clr-yellow-crayola: hsla(52, 100%, 76%, 1);
    --clr-macaroni-and-cheese: hsla(23, 100%, 74%, 1);

    --background: hsla(221, 18%, 32%, .7);
    --background-grn: hsla(83, 44%, 23%, .3);
    
    --spacer: 1rem;
    --box-shadow: 0 0.25em 1.5em rgba(0, 0, 0, 0.15);

    --box-shadow-btn: 0 1rem 1.25rem 0 rgba(92, 123, 172, 0.60),
        0 -0.25rem 1.5rem var(--clr-accent-olive-green) inset,
        0 0.75rem 0.5rem hsla(48, 100%, 94%, .5) inset,
        0 0.25rem 0.5rem 0 rgba(57, 75, 27, 1) inset;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

ul[class],
ol[class] {
    list-style:none;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
    white-space: nowrap;
    border: 0;
}

body {
    line-height: 1.5;
    /* min-height: 100vh; */
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.center {
    text-align: center;
}

.btn {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.05rem;

    cursor: pointer;
	position: relative;
	padding: 1.25rem 3.75rem;
	border-radius: 3.75rem;
	line-height: 1.25rem;
	font-size: 1rem;

    padding: .5rem 1.5rem;
	font-size: 1.25rem;

	font-weight: 600;

    border: 1px solid var(--clr-neutral-900);
    background-image: linear-gradient(-180deg, var(--clr-accent-lt-green) 0%, var(--clr-accent-dk-green) 100%);
    box-shadow: var(--box-shadow-btn);
}

.btn span {
    color: transparent;
    background-image: linear-gradient(0deg, var(--clr-accent-lt-green) 0%, var(--clr-cornsilk) 100%);
    -webkit-background-clip: text;
	background-clip: text;
	filter: drop-shadow(0 2px 2px var(--clr-neutral-800));
}

.btn::before {
    content: '';
    display: block;
    height: 0.125rem;
    position: absolute;
    top: 0.25rem;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 3.75rem);
    background: var(--clr-neutral-100);
    border-radius: 100%;
    opacity: 0.7;
    background-image: linear-gradient(-270deg, rgba(255,255,255,0.00) 0%, #fff 20%, #fff 80%, rgba(255,255,255,0.00) 100%);
}

.btn::after {
    content: "";
	display: block;
	height: 0125rem;
	position: absolute;
	bottom: 0.375rem;
	left: 50%;
	transform: translateX(-50%);
	width: calc(100% - 3.75rem);
	background: #fff;
	border-radius: 100%;

    filter: blur(1px);
	opacity: 0.05;
	background-image: linear-gradient(-270deg, rgba(255,255,255,0.00) 0%, #fff 20%, #fff 80%, rgba(255,255,255,0.00) 100%);
}




