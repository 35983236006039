@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,900;1,400;1,500;1,600;1,700;1,900&display=swap');

:root {
/* font-families */
    --ff-primary: 'Montserrat', sans-serif;

/* font-sizes */
    --fs-900: clamp(5rem, 8vw + 1rem, 9.375rem);
    --fs-800: 3.5rem;
    --fs-700: 1.75rem;
    --fs-600: 1.5rem;
    --fs-500: 1.25rem;
    --fs-400: 0.9375rem;
    --fs-300: 1rem;
    --fs-200: 0.875rem;
}


@media (min-width: 35em) {
    :root {
        --fs-800: 5rem;
        --fs-700: 2.5rem;
        --fs-600: 1.5rem;
        --fs-400: 1rem;
    }
}

@media (min-width: 45em) {
    :root {
        --fs-800: 6.25rem;
        --fs-700: 3.5rem;
        --fs-600: 2rem;
        --fs-400: 1.125rem;
    }
}



body {
    font-family: var(--ff-primary);
    font-size: var(--fs-400);
    font-weight: 400;
    color: var(--clr-neutral-900);
}

