:root {
    --box-shadow-btn__login: 0 1rem 1.25rem 0 #358fdc
        0 -0.25rem 1.5rem var(--clr-neutral-800) inset,
        0 0.75rem 0.5rem #d1eafd inset,
        0 0.25rem 0.5rem 0 #1649a0 inset;
}

.container--login {
    margin: 9rem 0 5rem;
}

.hero--login {
    max-width: 40%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.link--registerHere {
    text-transform: uppercase;
    font-size: 1.5rem;
    text-decoration: none;
    color: #154da5;
    text-shadow: 2px 2px 4px rgba(192, 227, 251, 1);
}

.form--login {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container--login fieldset {
    max-width: 25%;
    text-align: center;
    border: 2px solid #154da5;
    background: linear-gradient(0deg, #1649a0, #1355ae, #0e66c2, #358fdc, #7fbff1, #d1eafd, #f0f8fe);
    border-radius: 5rem;
    padding: 2rem;
    font-size: 1.5rem;
    margin-bottom: 3rem;
    color: #154da5;
    font-weight: 600;
    text-shadow: 2px 2px 4px rgba(192, 227, 251, 1);
}

.container--login input {
    width: 400px;
    border: 2px solid #154da5;
    border-radius: 8px;
    text-align: center;
}

.btn--login {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.1rem;

    cursor: pointer;
	position: relative;
	padding: 2.5rem 7.5rem;
	border-radius: 3.75rem;
	line-height: 2.5rem;
	font-size: 2rem;

    padding: 1rem 3rem;
	font-size: 1.5rem;

	font-weight: 600;

    border: 1px solid var(--clr-neutral-800);
    background-image: linear-gradient(-180deg, #7fbff1 0%, #1649a0 100%);
    box-shadow: var(--box-shadow-btn__login);
}

.btn--login span {
    color: transparent;
    background-image: linear-gradient(0deg, #7fbff1 0%, var(--clr-cornsilk) 100%);
    -webkit-background-clip: text;
	background-clip: text;
	filter: drop-shadow(0 2px 2px var(--clr-neutral-800));
}

.btn--login::before {
    content: '';
    display: block;
    height: 0.25rem;
    position: absolute;
    top: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 7.5rem);
    background: var(--clr-neutral-100);
    border-radius: 100%;
    opacity: 0.7;
    background-image: linear-gradient(-270deg, rgba(255,255,255,0.00) 0%, #fff 20%, #fff 80%, rgba(255,255,255,0.00) 100%);
}

.btn--login::after {
    content: "";
	display: block;
	height: 0.25rem;
	position: absolute;
	bottom: 0.75rem;
	left: 50%;
	transform: translateX(-50%);
	width: calc(100% - 7.5rem);
	background: #fff;
	border-radius: 100%;
}